// stylelint-disable block-no-empty

$base00: #2b2b2b;
$base01: #272935;
$base02: #3a4055;
$base03: #5a647e;
$base04: #d4cfc9;
$base05: #e6e1dc;
$base06: #f4f1ed;
$base07: #f9f7f3;
$base08: #da4939;
$base09: #cc7833;
$base0a: #ffc66d;
$base0b: #a5c261;
$base0c: #519f50;
$base0d: #6d9cbe;
$base0e: #b5b3eb;
$base0f: #bc9458;

$color-bg: $base00;
$color-fg: $base07;

$color-class: $base0a;
$color-comment: $base03;
$color-error: $base08;
$color-function: $base0d;
$color-keyword: $base0e;
$color-literal: $base09;
$color-number: $base09;
$color-string: $base0b;
$color-variable: $base08;

@mixin comment {
  color: $color-comment;
}

@mixin keyword {
  color: $color-keyword;
}

@mixin number {
  color: $color-number;
}

@mixin string {
  color: $color-string;
}

.highlight {
  background: $color-bg;
  border-radius: 5px;
  color: $color-fg;
  margin: 1em 0;
  padding: 1em;

  pre {
    background: $color-bg;
    color: $color-fg;
    margin: 0;
    overflow-x: auto;
  }

  // LineTable - TD

  .lntd {
    vertical-align: top;
    padding: 0;
    margin: 0;
    border: 0;
  }

  // Line Table
  .lntable {
    display: block;
    border-spacing: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: auto;
    overflow: auto;
  }

  // LineNumbers
  .ln {
    margin-right: 0.4em;
    padding: 0 0.4em 0 0.4em;
    color: #7f7f7f;
  }

  // Line Numbers - Table
  .lnt {
    margin-right: 0.4em;
    padding: 0 0.4em 0 0.4em;
    color: #7f7f7f;
  }

  // Comment
  .c {
    @include comment;
  }

  // Comment (Hashbang)
  .ch {
    @include comment;
  }

  // Comment (Multiline)
  .cm {
    @include comment;
  }

  // Comment (Preprocessed)
  .cp {
    @include comment;
  }

  // Comment (Preprocessed File)
  .cpf {
    @include comment;
  }

  // Comment (Single)
  .c1 {
    @include comment;
  }

  // Comment (Special)
  .cs {
    @include comment;
  }

  // Error
  .err {
    color: $color-error;
  }

  // Generic
  .g {}

  // Generic - Deletion
  .gd {
    background: $base00;
    color: $base08;
  }

  // Generic - Emphasis
  .ge {
    font-style: italic;
  }

  // Generic - Heading
  .gh {
    color: $base0d;
    font-weight: bold;
  }

  // Generic - Insertion
  .gi {
    background: $base00;
    color: $base0b;
  }

  // Generic - Underline
  .gl {}

  // Generic - Output
  .go {}

  // Generic - Prompt
  .gp {
    color: $base03;
    font-weight: bold;
  }

  // Generic - Error
  .gr {}

  // Generic - Strong
  .gs {
    font-weight: bold;
  }

  // Generic - Subheading
  .gu {
    color: $base0d;
    font-weight: bold;
  }

  // Generic - Traceback
  .gt {
  }

  // Highlight
  .hl,
  .hll {
    background-color: $base02;
  }

  // Keyword
  .k {
    @include keyword;
  }

  // Keyword - Constant
  .kc {
    @include keyword;
  }

  // Keyword - Declaration
  .kd {
    @include keyword;
  }

  // Keyword - Namespace
  .kn {
    color: $base0c;
  }

  // Keyword - Pseudo
  .kp {
    color: $color-function;
  }

  // Keyword - Reserved
  .kr {
    @include keyword;
  }

  // Keyword - Type
  .kt {
    color: $base0a;
  }

  // Literal
  .l {
    color: $color-literal;
  }

  // Literal - Date
  .ld {
    color: $color-literal;
  }

  // Number
  .m {
    @include number;
  }

  // Number - Binary
  .mb {
    @include number;
  }

  // Number - Float
  .mf {
    @include number;
  }

  // Number - Hex
  .mh {
    @include number;
  }

  // Number - Integer
  .mi {
    @include number;
  }

  // Number - Long Integer
  .il {
    @include number;
  }

  // Number - Octal
  .mo {
    @include number;
  }

  // Name
  .n {
    color: $color-fg;
  }

  // Name - Attribute
  .na {
    color: $base0d;
  }

  // Name - Builtin
  .nb {
    color: $color-function;
  }

  // Name - Built-in (Pseudo)
  .bp {
    color: $color-fg;
  }

  // Name - Class
  .nc {
    color: $color-class;
  }

  // Name - Constant
  .no {
    color: $base0a;
  }

  // Name -  Decorator
  .nd {
    color: $color-function;
  }

  // Name - Entity
  .ni {
    color: $color-fg;
  }

  // Name - Exception
  .ne {
    color: $color-class;
  }

  // Name - Function
  .nf {
    color: $base0d;
  }

  // Name - Function (Magic)
  .fm {
  }

  // Name - Label
  .nl {
    color: $color-fg;
  }

  // Name - Namespace
  .nn {
    color: $base0a;
  }

  // Name - Other
  .nx {
    color: $base0d;
  }

  // Name - Property
  .py {
    color: $base07;
  }

  // Name - Tag
  .nt {
    color: $base09;
  }

  // Name - Variable
  .nv {
    color: $color-variable;
  }

  // Name - Variable (Class)
  .vc {
    color: $color-variable;
  }

  // Name - Variable (Global)
  .vg {
    color: $color-variable;
  }

  // Name - Variable (Instance)
  .vi {
    color: $color-variable;
  }

  // Name - Variable (Magic)
  .vm {
    color: $color-variable;
  }

  // Operator
  .o {
    color: $color-fg;
  }

  // Operator - Word
  .ow {
    color: $color-fg;
  }

  // Punctuation
  .p {
    color: $color-fg;
  }

  // String
  .s {
    @include string;
  }

  // String - Single
  .s1 {
    @include string;
  }

  // String - Double
  .s2 {
    @include string;
  }

  // String - Backtick
  .sb {
    @include string;
  }

  // String - Char
  .sc {
    @include string;
  }

  // String - Delimeter
  .dl {
  }

  // String - Doc
  .sd {
    @include string;
  }

  // String - Escape
  .se {
    color: $base0f;
  }

  // String - Heredoc
  .sh {
    @include string;
  }

  // String - Interpolation
  .si {
    color: $base0f;
  }

  // String - Other
  .sx {
    @include string;
  }

  // String - Regex
  .sr {
    color: $base0c;
  }

  // String - Symbol
  .ss {
    @include string;
  }

  // Whitespace
  .w {
    color: $color-fg;
  }
}
