/* stylelint-disable scss/at-extend-no-missing-placeholder */

@import '../base/variables';

.post-comment {
  border-bottom: $light-gray 2px solid;
  border-top: $light-gray 2px solid;
  margin-bottom: modular-scale(1);
  padding: modular-scale(0) 0;

  &-author {
    align-items: center;
    border-bottom: $light-gray 2px solid;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: modular-scale(-1);
    padding-bottom: modular-scale(0);

    &-avatar {
      flex: 0 48px;
      margin-right: modular-scale(-2);
    }

    &-edit {
      @extend button;

      .comment-edit-link {
        color: $white;
      }
    }

    &-meta {
      display: flex;
      flex: 2 auto;
      flex-flow: column;
    }

    @media (min-width: $two-column-width) {
      &-meta {
        margin-bottom: 0;
      }
    }
  }

  &-reply {
    a {
      @extend button;
      display: block;

      @media (min-width: $two-column-width) {
        display: inline-block;
      }
    }
  }
}

.post-comment-form {
  &-submit {
    display: block;
    width: 100%;
  }
}
