.bio {
  display: flex;
  flex-direction: row;
  align-items: center;

  .bio-avatar-box {
    flex: 0 0 120px;
    margin-right: 1em;
  }

  .bio-avatar {
    border-radius: 100%;
  }
}
