.post {
  padding: 1em;

  &-content {
    color: $brand-text;
    font-size: modular-scale(1);
    margin: 0;
    text-align: justify;

    h2,
    h3,
    h4,
    h5,
    h6 {
      color: modular-lighten($brand-text, 1);
      text-transform: uppercase;
    }

    ul {
      list-style: square outside none;
      margin: 0 0 0.75em 1em;
    }
  }

  &-header {
    &-meta {
      color: modular-lighten($brand-text, 1);
      margin-bottom: modular-scale(-1);

      &-datum {
        display: inline-block;

        &:not(:first-child) {
          margin-left: modular-scale(-4);

          &::before {
            content: '\2022';
            margin-right: modular-scale(-4);
          }
        }
      }

      &-tags {
        display: inline-block;

        &-tag {
          display: inline-block;
          text-transform: uppercase;

          &:not(:last-child) {
            &::after {
              content: ',';
              margin-left: modular-scale(-10) * -1;
            }
          }
        }
      }
    }

    &-title {
      @include margin(0 0 modular-scale(-4) 0);
      color: modular-lighten($brand-text, 1);
      font-size: modular-scale(2);
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

.post-content {
  figure {
    margin: 0 0 $small-spacing 0;

    &:not(.highlight) {
      text-align: center;
    }

    img {
      margin: 0;
    }

    figcaption {
      font-size: modular-scale(-1);
    }
  }

  ul,
  ol {
    list-style-image: none;
    list-style-position: outside;
    margin: 0 0 modular-scale(-1) modular-scale(1);
  }

  ul {
    list-style-type: square;
  }

  ol {
    list-style-type: decimal;
  }

  li:first-child:not(:last-child),
  li ~ li {
    margin-bottom: modular-scale(-2);
  }

  .footnotes {
    font-size: modular-scale(-1);
  }

  .youtube-embed-wrapper, .speakerdeck-embed-wrapper {
    margin-bottom: 0.75em;
  }
}
